.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

a {
  font-weight: bold;
  color: #282C34;
}
a:hover {
  color: darkred;
}

.footer {
  background: #282C34;
  color: white;
  text-align: right;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar {
  width: 100%;
  background: #282C34;
  opacity: 1;
  font-weight: bold;
}

.navbar-relative {
  position: relative;
}

.navbar-fixed {
  position: fixed;
  top: 0;
  z-index: 1;
}

.menubar {
  width: 100%;
  display: flex;
  color: white;
}

.menubar div {
  margin-right: 12px;
}
.menubar div a:link, .menubar div a:visited {
  color: white;
}
.menubar div a:hover {
  color: darkred;
}